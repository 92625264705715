/*!
 * @copyright Copyright &copy; Kartik Visweswaran, Krajee.com, 2014
 * @package bootstrap-fileinput
 * @version 1.8.0
 *
 * File input styling for Bootstrap 3.0
 * Built for Yii Framework 2.0
 * Author: Kartik Visweswaran
 * Year: 2013
 * For more Yii related demos visit http://demos.krajee.com
 */
.file-input{overflow-x: auto}.file-loading{top:0;right:0;width:25px;height:25px;font-size:999px;text-align:right;color:#fff;background:transparent url(../img/loading.gif) top left no-repeat;border:none}.btn-file{position:relative;overflow:hidden}.btn-file input[type=file]{position:absolute;top:0;right:0;min-width:100%;min-height:100%;font-size:999px;text-align:right;filter:alpha(opacity=0);opacity:0;background:red;cursor:inherit;display:block}.file-caption .glyphicon{display:inline-block;min-width:18px;float:left;margin-top:2px}.file-caption-name{display:inline-block;float:left;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;width:85%}.file-caption-disabled{background-color:#EEE;cursor:not-allowed;opacity:1}.file-input .btn .disabled,.file-input .btn[disabled]{cursor:not-allowed}.file-preview{border-radius:5px;border:1px solid #ddd;padding:5px;width:100%;margin-bottom:5px}.file-preview-frame{display:table;margin:8px;height:160px;border:1px solid #ddd;box-shadow:1px 1px 5px 0 #a2958a;padding:6px;float:left;text-align:center}.file-preview-frame:hover{background-color:#eee;box-shadow:2px 2px 5px 0 #333}.file-preview-image{height:150px;vertical-align:text-center}.file-preview-text{display:table-cell;width:150px;height:150px;color:#428bca;font-size:11px;vertical-align:middle;text-align:center}.file-preview-other{display:table-cell;width:150px;height:150px;font-family:Monaco,Consolas,monospace;font-size:11px;vertical-align:middle;text-align:center}.file-input-new .close,.file-input-new .file-preview,.file-input-new .fileinput-remove-button,.file-input-new .fileinput-upload-button,.file-input-new .glyphicon-file{display:none}.loading{background:transparent url(../img/loading.gif) no-repeat scroll center center content-box!important}.wrap-indicator{font-weight:700;color:#245269;cursor:pointer}